import React, { useState, useEffect } from "react";
import CustomButton from "./Button";

function ExcelUploadMulti({ onFileChange, acceptImage, returnOriginal, multiple = false, accept }) {
  const [filesArr, setFilesArr] = useState([]);
  const [value, setValue] = useState([]);
  const [filesConversionToJSONCompleted, setFilesConversionToJSONCompleted] = useState(false);
  const getBase64 = (file, cb) => {
    if (file) {

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error)
      };
    }
  };
  const handleFileSelection = async (event) => {
    event.preventDefault();
    let tempArr = [];
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i <= event.target.files.length - 1; i++) {
        getBase64(event.target.files[i], (result) => {
          console.log(event.target.files[i], "event.target.files[i]")
          tempArr.push({ link: event.target.files[i], base64: result });
          if (i == event.target.files.length - 1) {
            setFilesConversionToJSONCompleted(true)
            setFilesArr([...tempArr])
            setValue("")
          }
          else {
            setFilesConversionToJSONCompleted(false)
            setValue(event.target.files[i])
          }
        });
      }
    }
  };

  useEffect(() => {
    if (filesConversionToJSONCompleted) {
      onFileChange(filesArr);

    }
  }, [filesConversionToJSONCompleted])




  return (
    <div className="position-relative">
      <input multiple type="file" onChange={(event) => handleFileSelection(event)} className="form-control" accept={accept} />

      {/* <CustomButton isLink extraClass="position-absolute start-0 top-0 h-100 text-uppercase rounded-0" noIcon btnName="Browse" /> */}
    </div>
  );
}


export default ExcelUploadMulti;


