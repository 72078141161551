import { Route, Routes } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import ExcelUploadForm from "../components/Flipkart-pdf-components/ExcelUploadForm/ExcelUploadForm";
import MeeshoExelUpload from "../components/Flipkart-pdf-components/MeeshoExcelUpload/MeeshoExcelUpload";
import ProductPdfForm from "../components/Flipkart-pdf-components/ProductPdfForm/ProductPdfForm";
import Header from "../components/Layout/Header";
import SideBar from "../components/Sidebar/SideBar";
import MeeshoPdfUpload from "../components/Flipkart-pdf-components/MeeshoPdfUpload/MeeshoPdfUpload";
import ExcelProcessor from "../components/ExcelProcessor/ExcelProcessor";
import ExcelProcessorBaseExcel from "../components/ExcelProcessor/ExcelProcessorBaseExcel";
export default function AuthorizedRoutes() {
  return (
    <section style={{ backgroundColor: "#ebebeb" }}>
      <div className="row g-0">
        <div className="col-12 col-md-2 no-print" style={{ contain: "content" }}>
          <SideBar />
        </div>
        <div className="col-12 col-md-10" style={{ height: "100vh", overflow: "hidden scroll" }}>
          <Header />
          <Routes>
            <Route exact path="/" element={<ExcelUploadForm />}></Route>
            <Route exact path="/Products-Excel" element={<ExcelUploadForm />}></Route>
            <Route exact path="/pdf-processor" element={<ProductPdfForm />}></Route>
            <Route exact path="/Meesho-Products-Excel" element={<MeeshoExelUpload />}></Route>
            <Route exact path="/Meesho-Products-Pdf" element={<MeeshoPdfUpload />}></Route>
            <Route exact path="/Upload-Excel-Processor-Base-Excel" element={<ExcelProcessorBaseExcel />}></Route>
            <Route exact path="/Excel-Processor" element={<ExcelProcessor />}></Route>
          </Routes>
        </div>
      </div>
    </section>
  );
}
