import React, { useEffect, useState } from 'react'
import { uploadExcel } from '../../../services/Excel.service';
import { uploadPdfAndReturnNewPdf } from '../../../services/users.service';
import CustomButton from '../../Utility/Button'
import { DashboardBox } from '../../Utility/DashboardBox'
import FileUpload from '../../Utility/FileUpload'
import FileUploadMulti from '../../Utility/FileUploadMulti';
import { toastError, toastSuccess } from '../../Utility/ToastUtils';
import loader from "../../../assets/images/loader2.gif"
import { url } from '../../../services/url.service';
export default function ProductPdfForm() {
    const [file, setFile] = useState({});

    const [loading, setLoading] = useState(false);
    const [downloadBtnVisible, setDownloadBtnVisible] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");
    const [labelLink, setLabelLink] = useState("");
    const [billLink, setBillLink] = useState("");

    const handleFileChange = (e) => {
        console.log(e, "event")
        setFile(e)
    }

    const handleDownloadBillFile = () => {
        window.open(`${billLink}`, '_blank');
    }
    const handleDownloadLabelFile = () => {
        window.open(`${labelLink}`, '_blank');
    }



    const handleSubmit = async () => {
        setLoading(true)
        setDownloadUrl("")
        try {
            if (!file || file.length == 0) {
                toastError("File cannot be empty !!!");
                setLoading(false)
                return
            }
            console.log(file, ".pdf")
            let formData = new FormData()
            console.log("FILE", file, file?.length)
            // for (let i = 0; i <= file.length - 1; i++) {
            formData.append("file", file)
            // }
            let { data: res } = await uploadPdfAndReturnNewPdf(formData);
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message)
                console.log(res, "asda")
                if (res.labelLink) {
                    setDownloadBtnVisible(true);
                    setDownloadUrl(`${url}${res.zipLink}`);
                    setLabelLink(`${url}${res.labelLink}`);
                    setBillLink(`${url}${res.billLink}`);
                    // window.open(`${url}${res.zipLink}`, '_blank');
                }
            }
        }
        catch (err) {
            setLoading(false);
            setDownloadBtnVisible(false);
            toastError(err);
        }
    }




    useEffect(() => {
        if (downloadUrl != "") {
            setDownloadBtnVisible(true)
        }
        else {
            setDownloadBtnVisible(false)
        }

    }, [downloadUrl])



    if (loading) {
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(255,255,255,0.9)" }}>
                <div className='row d-flex justify-content-center align-items-center'>
                    <img src={loader} alt="" style={{ height: 250, width: 250 }} />
                    <div className='text-center'>Processing...</div>
                    <div className='text-center'>We are processing your request , you can check after 3 - 4 minutes to get a download button for your output zip file</div>
                </div>
            </div>
        )
    }
    else {

        return (
            <div className="container-fluid">
                <DashboardBox>
                    <div className="row">
                        <h5 className="blue-1 mb-4">Upload Flipkart's Pdf's</h5>
                        <div className="col-md-6 mb-3">
                            <label className="mb-2">
                                Select single<span className="red">*</span> (Only pdf's are allowed)
                            </label>

                            <FileUpload returnOriginal accept={".pdf"} onFileChange={handleFileChange} />

                            {/* <FileUploadMulti onFileChange={handleFileChange} /> */}
                        </div>
                        <div className="col-12 mt-2">
                            <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn noIcon={true} iconName="fa-solid fa-check" btnName={"Upload Selected pdf's"} />
                        </div>
                        {
                            downloadBtnVisible &&
                            <div className="col-12 mt-2">
                                <CustomButton btntype="button" ClickEvent={handleDownloadLabelFile} isBtn noIcon={true} iconName="fa-solid fa-check" changeClass={"btn-success btn my-5 me-4"} btnName={"Download Label file"} />
                                <CustomButton btntype="button" ClickEvent={handleDownloadBillFile} isBtn noIcon={true} iconName="fa-solid fa-check" changeClass={"btn-success btn my-5"} btnName={"Download Bill file"} />
                            </div>
                        }
                    </div>
                </DashboardBox>
            </div>
        )
    }
}
