import React, { useEffect, useState } from 'react';
import loader from "../../assets/images/loader2.gif";
import { AddFilesToExcelProcessor } from '../../services/ExcelProcessor.service';
import CustomButton from '../Utility/Button';
import { DashboardBox } from '../Utility/DashboardBox';
import ExcelUploadMulti from '../Utility/ExcelUploadMulti';
import { toastError, toastSuccess } from '../Utility/ToastUtils';
export default function ExcelProcessor() {
    const [file, setFile] = useState({});

    const [loading, setLoading] = useState(false);
    const [downloadBtnVisible, setDownloadBtnVisible] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");
    const [orderFile, setOrderFile] = useState({});
    const [productsArr, setProductsArr] = useState([]);
    const [finalNestedArr, setFinalNestedArr] = useState([]);
    const handleFileChange = (e) => {
        setFile(e)
    }

    const handleDownloadFile = () => {
        window.open(`${downloadUrl}`, '_blank');
    }



    const handleSubmit = async () => {
        // setLoading(true)
        setDownloadUrl("")
        try {
            if (!file || file.length == 0) {
                toastError("File cannot be empty !!!");
                setLoading(false)
                return
            }

            let obj = {
                // fileArr: file.map(el => el.base64),
                orderCsv: orderFile[0].base64,
            }

            console.log(file, "file")
            // let formData = new FormData()
            // console.log("FILE", file, file?.length)
            // for (let i = 0; i <= file.length - 1; i++) {
            //     formData.append("file", file[i])
            // }
            // console.log(orderFile[0], "orderFile")
            // formData.append("orderCsv", orderFile[0])

            let { data: res } = await AddFilesToExcelProcessor(obj);
            console.log(res, "response")
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message)
                setProductsArr(res.data)
                console.log(res.finalNestedArr, "finalNestedArr")
                setFinalNestedArr(res.finalNestedArr)
            }
        }
        catch (err) {
            setLoading(false);
            setDownloadBtnVisible(false);
            toastError(err);
        }
    }




    useEffect(() => {
        if (downloadUrl != "") {
            setDownloadBtnVisible(true)
        }
        else {
            setDownloadBtnVisible(false)
        }

    }, [downloadUrl])

    const handleToggleSize = (index, indexX) => {
        let tempArr = finalNestedArr
        tempArr[index].sizeArr[indexX].checked = !tempArr[index].sizeArr[indexX].checked
        setFinalNestedArr([...tempArr])
    }

    const handleToggleCategory = (index) => {
        let tempArr = finalNestedArr
        tempArr[index].checked = !tempArr[index].checked
        setFinalNestedArr([...tempArr])
    }

    if (loading) {
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(255,255,255,0.9)" }}>
                <div className='row d-flex justify-content-center align-items-center'>
                    <img src={loader} alt="" style={{ height: 250, width: 250 }} />
                    <div className='text-center'>Processing...</div>
                    <div className='text-center'>We are processing your request , you can check after 2 - 3 minutes to get a download button for your output zip file</div>
                </div>
            </div>
        )
    }
    else {

        return (
            <div className="container-fluid">
                <DashboardBox>
                    <div className="row mb-5 pb-5">
                        <h5 className="blue-1 mb-4"> Upload Excel For Processing</h5>
                        {/* <div className="col-md-6 mb-3">
                            <label className="mb-2">
                                Select single or multiple files (Flipkart sku files only)<span className="red">*</span> (Only xlsx's are allowed)
                            </label>
                            <ExcelUploadMulti onFileChange={handleFileChange} accept={".xlsx"} />
                        </div> */}
                        <div className="col-md-6 mb-3">
                            <label className="mb-2">
                                Select single or multiple files (Order CSV only)<span className="red">*</span> (Only csv's are allowed)
                            </label>
                            <ExcelUploadMulti onFileChange={(e) => { setOrderFile(e); }} accept={".csv"} />
                        </div>
                        <div className="col-12 mt-2">
                            <CustomButton btntype="button" ClickEvent={(e) => { e.preventDefault(); handleSubmit(e) }} isBtn noIcon={true} iconName="fa-solid fa-check" btnName={"Upload Selected pdf's"} />
                        </div>
                        {
                            downloadBtnVisible &&
                            <div className="col-12 mt-2">
                                <CustomButton btntype="button" ClickEvent={(e) => { e.preventDefault(); handleDownloadFile(e) }} isBtn noIcon={true} iconName="fa-solid fa-check" changeClass={"btn-success btn my-5"} btnName={"Download pdf file"} />
                            </div>
                        }
                    </div>




                    {
                        finalNestedArr && finalNestedArr.length > 0 &&
                        <h5 className="blue-1 mb-4 mt-5"> Your Results</h5>
                    }

                    {
                        finalNestedArr && finalNestedArr.length > 0 && finalNestedArr.map((el, index) => {
                            return (
                                <div key={index}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: el.checked ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.08)", padding: "15px 10px", borderRadius: 10, marginTop: 15 }} key={index} onClick={() => handleToggleCategory(index)}>
                                        <div style={{ color: el.checked ? "white" : "black" }}>
                                            {el.category}
                                        </div>
                                        <div style={{ color: el.checked ? "white" : "black" }}>{el.totalPcs}</div>
                                    </div>

                                    {
                                        el.checked &&
                                        <div key={index} style={{ flex: 1 }}>
                                            {
                                                el.sizeArr && el.sizeArr.length > 0 && el.sizeArr.map((ele, indexX) => {
                                                    return (
                                                        <div key={indexX} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleToggleSize(index, indexX) }}>
                                                            <div style={{ backgroundColor: ele.checked ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.02)", display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "10px 5px", marginRight: "auto", width: "95%", padding: "15px 10px", borderRadius: 10 }}>
                                                                <div style={{ color: ele.checked ? "white" : "black" }}>{ele.name}</div>
                                                                <div style={{ color: ele.checked ? "white" : "black" }}>{ele.sizeQuantity}</div>
                                                            </div>

                                                            {
                                                                ele.checked &&
                                                                <div key={indexX} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} style={{ border: "solid 1px grey", margin: "20px 5px", marginRight: "auto", width: "90%", alignSelf: "flex-end", padding: 15, borderRadius: 10 }}>
                                                                    {
                                                                        ele.colorArr && ele.colorArr.length > 0 && ele.colorArr.map((ele, indexX) => {
                                                                            return (
                                                                                <div style={{ display: "flex", padding: "10px 0px", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                    <div>{ele.name}</div>
                                                                                    <div>{ele.quantity}</div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }

                                                                </div>
                                                            }


                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }








                    {/* <table class="table mt-4">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Color</th>
                                <th scope="col">Category</th>
                                <th scope="col">Size</th>
                                <th scope="col">No Of PCS</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Total PCS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                productsArr && productsArr.length > 0 && productsArr.map((el, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">{index + 1}.)</th>
                                            <td> {el.sku}</td>
                                            <td>{el.color}</td>
                                            <td>{el.category}</td>
                                            <td>{el.size}</td>
                                            <td> {el.noOfPcs} </td>
                                            <td>{el.quantity}</td>
                                            <td> {el.totalPcs} </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <th colSpan={6}>Grand Totals</th>
                                <th>{productsArr.reduce((acc, el) => acc + el.quantity, 0)}</th>
                                <th>{productsArr.reduce((acc, el) => acc + (el.totalPcs != "No data found" ? el.totalPcs : 0), 0)}</th>
                            </tr>

                        </tbody>
                    </table> */}



                </DashboardBox>
            </div>
        )
    }
}
