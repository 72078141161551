import React, { useState } from 'react'
import { downloadExcel, uploadExcel } from '../../services/Excel.service';
import { url } from '../../services/url.service';
import CustomButton from '../Utility/Button'
import { DashboardBox } from '../Utility/DashboardBox'
import FileUpload from '../Utility/FileUpload'
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { downloadFlipkartSKUExcel, uploadFlipkartSKUExcel } from '../../services/Excel.service';

export default function ExcelProcessorBaseExcel() {
    const [file, setFile] = useState({});
    const handleFileChange = (e) => {
        console.log(e, "event")
        setFile(e)
    }

    const handleSubmit = async () => {
        try {
            if (!file || !file.name) {
                toastError("File cannot be empty !!!");
                return
            }

            let formData = new FormData()
            formData.append("file", file)
            let { data: res } = await uploadFlipkartSKUExcel(formData);
            if (res.message) {
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err);
        }
    }

    const handleDownloadCurrentExcel = async () => {
        try {
            let { data: res } = await downloadFlipkartSKUExcel();
            if (res.link) {
                window.open(`${url}/${res.link}`, '_blank');
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err);
        }



    }


    return (
        <div className="container-fluid">
            <DashboardBox>
                <div className="row">
                    <h5 className="blue-1 mb-4">Flipkart's Product Excel Upload</h5>
                    <div className="col-md-6 mb-3">
                        <label className="mb-2">
                            Select Excel File<span className="red">*</span>
                        </label>
                        <FileUpload onFileChange={handleFileChange} returnOriginal={true} />
                    </div>
                    <div className="col-md-6 mb-3 d-flex justify-content-end">
                        <div onClick={() => { handleDownloadCurrentExcel() }} className="mb-2 blue-1" style={{ cursor: "pointer" }}>
                            Download current excel file
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn noIcon={true} iconName="fa-solid fa-check" btnName={"Upload Excel"} />
                    </div>
                </div>
            </DashboardBox>
        </div>
    )
}
