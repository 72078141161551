import React, { useState } from "react";
import CustomButton from "./Button";

function FileUploadMulti({ onFileChange }) {
  const [file, setFile] = useState("");

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error)
    };
  };

  const handleFileSelection = (event) => {
    event.preventDefault()
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files);
      onFileChange(event.target.files);
    }
  };
  return (
    <div className="position-relative">

      <input type="file" onChange={(event) => handleFileSelection(event)} className="form-control" multiple={true} accept=".pdf" />
      <CustomButton isBtn styles={{ backgroundColor: "#C59D69" }} extraClass="position-absolute start-0 top-0 h-100 text-uppercase rounded-0" noIcon btnName="Browse" />
    </div>
  );
}

export default FileUploadMulti;


