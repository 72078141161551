// import React, { useState } from 'react'
// import { downloadExcel, uploadExcel } from '../../../services/Excel.service';
// import { url } from '../../../services/url.service';
// import CustomButton from '../../Utility/Button'
// import { DashboardBox } from '../../Utility/DashboardBox'
// import FileUpload from '../../Utility/FileUpload'
// import { toastError, toastSuccess } from '../../Utility/ToastUtils';
// import * as pdfjsLib from 'pdfjs-dist';
// export default function MeeshoPdfUpload() {
//     const [file, setFile] = useState({});
//     const handleFileChange = (e) => {
//         console.log(e, "event")
//         setFile(e)
//     }




//     const handleSubmit = async () => {
//         try {
//             if (!file || !file.name) {
//                 toastError("File cannot be empty !!!");
//                 return
//             }

//             let formData = new FormData()
//             formData.append("file", file)
//             let { data: res } = await uploadMeeshoPdf(formData);
//             if (res.message) {
//                 toastSuccess(res.message)
//             }
//         }
//         catch (err) {
//             toastError(err);
//         }
//     }

//     const handleDownloadCurrentExcel = async () => {
//         try {
//             let { data: res } = await downloadExcel();
//             if (res.link) {
//                 window.open(`${url}/${res.link}`, '_blank');
//                 toastSuccess(res.message)
//             }
//         }
//         catch (err) {
//             toastError(err);
//         }



//     }


//     return (
//         <div className="container-fluid">
//             <DashboardBox>
//                 <div className="row">
//                     <h5 className="blue-1 mb-4">Product Excel Upload</h5>
//                     <div className="col-md-6 mb-3">
//                         <label className="mb-2">
//                             Select Excel File<span className="red">*</span>
//                         </label>
//                         <FileUpload accept={".pdf,.doc"} onFileChange={handleFileChange} returnOriginal={true} />
//                     </div>
//                     <div className="col-md-6 mb-3 d-flex justify-content-end">
//                         <div onClick={() => { handleDownloadCurrentExcel() }} className="mb-2 blue-1" style={{ cursor: "pointer" }}>
//                             Download current excel file
//                         </div>
//                     </div>
//                     <div className="col-12 mt-2">
//                         <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn noIcon={true} iconName="fa-solid fa-check" btnName={"Upload Excel"} />
//                     </div>
//                 </div>
//             </DashboardBox>
//         </div>
//     )
// }

import React, { useEffect, useState } from 'react';
import loader from "../../../assets/images/loader2.gif";
import { url } from '../../../services/url.service';
import { uploadPdfAndReturnNewPdf } from '../../../services/users.service';
import CustomButton from '../../Utility/Button';
import { DashboardBox } from '../../Utility/DashboardBox';
import FileUploadMulti from '../../Utility/FileUploadMulti';
import { toastError, toastSuccess } from '../../Utility/ToastUtils';
import { uploadMeeshoPdf } from '../../../services/Meesho.service';
export default function MeeshoPdfUpload() {
    const [file, setFile] = useState({});

    const [loading, setLoading] = useState(false);
    const [downloadBtnVisible, setDownloadBtnVisible] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState("");

    const handleFileChange = (e) => {
        console.log(e, "event")
        setFile(e)
    }

    const handleDownloadFile = () => {
        window.open(`${downloadUrl}`, '_blank');
    }



    const handleSubmit = async () => {
        setLoading(true)
        setDownloadUrl("")
        try {
            if (!file || file.length == 0) {
                toastError("File cannot be empty !!!");
                setLoading(false)
                return
            }

            let formData = new FormData()
            console.log("FILE", file, file?.length)
            for (let i = 0; i <= file.length - 1; i++) {
                formData.append("file", file[i])
            }
            let { data: res } = await uploadMeeshoPdf(formData);
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message)
                if (res.zipLink) {
                    setDownloadUrl(`${url}${res.zipLink}`);
                    // window.open(`${url}${res.zipLink}`, '_blank');
                }
            }
        }
        catch (err) {
            setLoading(false);
            setDownloadBtnVisible(false);
            toastError(err);
        }
    }




    useEffect(() => {
        if (downloadUrl != "") {
            setDownloadBtnVisible(true)
        }
        else {
            setDownloadBtnVisible(false)
        }

    }, [downloadUrl])



    if (loading) {
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(255,255,255,0.9)" }}>
                <div className='row d-flex justify-content-center align-items-center'>
                    <img src={loader} alt="" style={{ height: 250, width: 250 }} />
                    <div className='text-center'>Processing...</div>
                    <div className='text-center'>We are processing your request , you can check after 2 - 3 minutes to get a download button for your output zip file</div>
                </div>
            </div>
        )
    }
    else {

        return (
            <div className="container-fluid">
                <DashboardBox>
                    <div className="row">
                        <h5 className="blue-1 mb-4"> Upload Meesho's Pdf's</h5>
                        <div className="col-md-6 mb-3">
                            <label className="mb-2">
                                Select single or multiple files<span className="red">*</span> (Only pdf's are allowed)
                            </label>
                            <FileUploadMulti onFileChange={handleFileChange} />
                        </div>
                        <div className="col-12 mt-2">
                            <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn noIcon={true} iconName="fa-solid fa-check" btnName={"Upload Selected pdf's"} />
                        </div>
                        {
                            downloadBtnVisible &&
                            <div className="col-12 mt-2">
                                <CustomButton btntype="button" ClickEvent={handleDownloadFile} isBtn noIcon={true} iconName="fa-solid fa-check" changeClass={"btn-success btn my-5"} btnName={"Download pdf file"} />
                            </div>
                        }
                    </div>
                </DashboardBox>
            </div>
        )
    }
}
