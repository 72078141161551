import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/productExcel";

export const uploadExcel = (formData) => {
    return axios.post(serverUrl + "/", formData);
};
export const uploadMeeshoExcel = (formData) => {
    return axios.post(serverUrl + "/meesho", formData);
};
export const uploadFlipkartSKUExcel = (formData) => {
    return axios.post(serverUrl + "/flipkartSku", formData);
};

export const downloadExcel = () => {
    return axios.get(`${serverUrl}/`);
};
export const downloadMeeshoExcel = () => {
    return axios.get(`${serverUrl}/MeeshoExcel`);
};

export const downloadFlipkartSKUExcel = () => {
    return axios.get(`${serverUrl}/getFlipkartSku`);
};