import React, { useState } from 'react'
import { downloadExcel, downloadMeeshoExcel, uploadExcel, uploadMeeshoExcel } from '../../../services/Excel.service';
import { url } from '../../../services/url.service';
import CustomButton from '../../Utility/Button'
import { DashboardBox } from '../../Utility/DashboardBox'
import FileUpload from '../../Utility/FileUpload'
import { toastError, toastSuccess } from '../../Utility/ToastUtils';
import * as pdfjsLib from 'pdfjs-dist';
export default function MeeshoExelUpload() {
    const [file, setFile] = useState({});
    const handleFileChange = (e) => {
        console.log(e, "event")
        setFile(e)

        handleParsePdf(e)


    }



    const handleParsePdf = (fileBlob) => {
        // Load the PDF file using PDF.js
        console.log(fileBlob)
        var typedarray = new Uint8Array(fileBlob);
        pdfjsLib.getDocument(typedarray).promise.then(function (pdf) {
            console.log(pdf, "parsed ")
            // Iterate over each page of the PDF file
            for (var pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {

                // Get the page object for the current page
                pdf.getPage(pageNumber).then(function (page) {

                    // Set the scale for the rendering
                    var scale = 1.5;

                    // Get the viewport for the page at the desired scale
                    var viewport = page.getViewport({ scale: scale });

                    // Create a canvas element to render the page on
                    var canvas = document.createElement('canvas');
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    var context = canvas.getContext('2d');

                    // Render the page on the canvas
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    page.render(renderContext).promise.then(function () {

                        // Convert the canvas to a PNG image
                        var imageData = canvas.toDataURL('image/png');

                        // Do something with the image data, such as save it or display it on the page
                        console.log('Page ' + pageNumber + ' rendered as image:');
                        console.log(imageData);

                    });

                });

            }

        });
    }

    const handleSubmit = async () => {
        try {
            if (!file || !file.name) {
                toastError("File cannot be empty !!!");
                return
            }

            let formData = new FormData()
            formData.append("file", file)
            let { data: res } = await uploadMeeshoExcel(formData);
            if (res.message) {
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err);
        }
    }

    const handleDownloadCurrentExcel = async () => {
        try {
            let { data: res } = await downloadMeeshoExcel();
            if (res.link) {
                window.open(`${url}/${res.link}`, '_blank');
                toastSuccess(res.message)
            }
        }
        catch (err) {
            toastError(err);
        }



    }


    return (
        <div className="container-fluid">
            <DashboardBox>
                <div className="row">
                    <h5 className="blue-1 mb-4">Meesho's Product Excel Upload</h5>
                    <div className="col-md-6 mb-3">
                        <label className="mb-2">
                            Select Excel File<span className="red">*</span>
                        </label>
                        {/* accept={".pdf,.doc"} */}
                        <FileUpload onFileChange={handleFileChange} returnOriginal={true} />
                    </div>
                    <div className="col-md-6 mb-3 d-flex justify-content-end">
                        <div onClick={() => { handleDownloadCurrentExcel() }} className="mb-2 blue-1" style={{ cursor: "pointer" }}>
                            Download current excel file
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn noIcon={true} iconName="fa-solid fa-check" btnName={"Upload Excel"} />
                    </div>
                </div>
            </DashboardBox>
        </div>
    )
}
