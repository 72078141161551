// 

import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/ExcelProcessor";


export const AddFilesToExcelProcessor = (formData) => {

    return axios.post(`${serverUrl}/ExcelProcessor/`, formData);
};
